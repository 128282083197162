<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine">
      <div class="common-plate-title">
        <span>{{ $trans('绑定邮箱', '绑定邮箱') }}</span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="setting-input-box flex-bet-cen">
        <input
          type="text"
          :placeholder="$trans('请输入您的邮箱', '请输入您的邮箱')"
          v-model="email"
        />
      </div>
      <div class="btn-fill" @click="emailHandle">
        {{ $trans('提交信息', '提交信息') }}
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, revampUserInfo } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      email: '',
      data: {},
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getUserInfo({ userId: this.$store.state.userId }).then((res) => {
        if (res.code == 200) {
          this.data = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    // 验证邮箱
    emailHandle() {
      let emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!emailReg.test(this.email)) {
        this.$message.warning(this.$trans('请认真填写邮箱', '请认真填写邮箱'))
        return
      }
      let params = {
        email: this.email,
        id: this.data.id,
      }
      revampUserInfo(params)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(this.$trans('修改邮箱成功', '修改邮箱成功'))
            this.$router.history.push('/setting')
          } else {
            this.$message.error(
              this.$trans('验证失败,请稍后重试', '验证失败,请稍后重试')
            )
            this.email = ''
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('网络错误，请稍后再试', '网络错误，请稍后再试')
          )
        })
    },
  },
}
</script>
<style scoped>
.common-plate-title {
  margin-bottom: 100px;
}
.setting-input-box {
  height: 64px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 20px;
}
.setting-input-box input {
  flex: 1;
  outline: none;
  border: none;
  margin-right: 20px;
  background: transparent;
}
.btn-fill {
  cursor: pointer;
  width: 300px;
  margin: 63px auto 0;
}
</style>
